import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

import MainPage from "./pages/MainPage";
import WorkInProgress from "./pages/WorkInProgress";
import DevOpsPractices from "./pages/DevOpsPractices";
import AWSCloud from "./pages/AWSCloud";
import Paas from "./pages/Paas";
import Leadership from "./pages/Leadership";
import About from "./pages/About";
import PrivacyPolicy from "./pages/PrivacyPolicy";
// import HowDoIWork from "./pages/HowDoIWork";
import EC from "./pages/casestudy/EC2";
import ECS from "./pages/casestudy/ECS";
import EKS from "./pages/casestudy/EKS";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<MainPage />} exact />
          <Route path="/devops" element={<DevOpsPractices />} exact />
          <Route path="/aws" element={<AWSCloud />} exact />
          <Route path="/paas" element={<Paas />} exact />
          <Route path="/leadership" element={<Leadership />} exact />
          <Route path="/blog" element={<WorkInProgress />} exact />
          <Route path="/blog/:category" element={<WorkInProgress />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* <Route path="/how-do-i-work" element={<HowDoIWork />} /> */}
          <Route path="/casestudy/ec2" element={<EC />} />
          <Route path="/casestudy/ecs" element={<ECS />} />
          <Route path="/casestudy/eks" element={<EKS />} />
          <Route path="/*" element={<MainPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
