function DevOpsPractices() {
  const items = [
    {
      id: "1",
      name: "Agile planning and execution process",
      image: require("../assets/project-management.png"),
      summary:
        "Enabling DevOps practices allows you to identify the most significant wins you can deliver in the shortest time based on the collected metrics and enabled feedback loops from users of your system. Addressing identified waste during Value Streams observation and items on the critical path will help you to deliver faster with less effort. Using Scrum / SAFe and Lean practicies in your organisation will help to build solid plan and common understanding around your delivery in the organisation.",
    },
    {
      id: "2",
      name: "Automate boilerplate work",
      image: require("../assets/automation.png"),
      summary:
        "Putting effort into automating the most trivial, time-consuming activities will free up your team members. That should increase their motivation (as they will be working on the more exciting tasks) and improve overall team productivity. For example, you can automate changelog creation, tagging and releasing processes or make testing activities part of your pipeline.",
    },
    {
      id: "3",
      name: "Continous Integration & Delivery",
      image: require("../assets/cicd.png"),
      summary:
        "Enabling CI/CD pipeline is one of the core principles of the modern DevOps delivery circle. Automating artefact generation and deployment to the lower environments (Continous Delivery) or even up to production (Continous Deployment) is one of the most significant milestones in building DevOps capabilities in every organisation. In my carrier, I used many tools supporting this task, starting from Jenkins, GitLab CI or GitHub Actions as a delivery tool through artefacts storage systems like Nexus, Artifactory or AWS ECR finishing and security scanning tools (SonarQube, Artifactory XRay or AWS ECR). I've built pipelines executing many different types of automated tests and, in the last project, also applied the GitOps pattern with ArgoCD.",
    },
    {
      id: "4",
      name: "Live monitoring",
      image: require("../assets/dashboard.png"),
      summary:
        "You can only run a production system with proper observability. I built observability with tools like AWS CloudWatch, DataDog, AppDynamics, NewRelic, Prometheus, Graphana, Elastic Stack and Splunk. Almost every team I worked with was implementing this part of the system differently, but they all had a common goal. Collect system (infrastructure and application) metrics, deliver dashboards to present system behaviour to the stakeholders and alert in case thresholds were exceeded. So then you can youse tools like PagerDuty to alert on mobile, email or messengers apps.",
    },
  ];

  return (
    <>
      <div className="row mb-3 mt-3">
        <div className="container mb-1 text-left">
          <h1 className="mb-4 mt-4 text-blue">DevOps Practicies & Toolset</h1>
          <p>
            I'm DevOps practicioner for over last 8 years. At that time I was a
            team member of different Core/Platform teams, which very often were
            enablers of the DevOps practicies and tools for the organisation.
            <br />
            <br />
            Below graphic shows infinitive application delivery circle, which
            DevOps practice & toolset is part of every stage. I also wrap up
            some of the common practicies these teams are focused on:
          </p>
        </div>
        <img
          className="container"
          src={require("../assets/devops-infinity.png")}
          alt=""
        />
      </div>
      <ItemsList items={items} />
    </>
  );
}

function ItemsList(props) {
  return (
    <div className="container-fluid bg-grey-2">
      <div className="container mb-4 mt-5 text-center">
        {props.items.map((item, index) => {
          return <Item key={item.id} item={item} />;
        })}
      </div>
    </div>
  );
}

function Item(props) {
  return (
    <div className="row mb-3 mt-3">
      <div className="container col-md-2 pb-3 pt-3 rounded-5 bg-grey">
        <img
          className="container max-width-150"
          src={props.item.image}
          alt=""
        />
      </div>
      <div className="container col-md-10 mb-1 text-left">
        <h3 className="mb-1 mt-1 text-blue">{props.item.name}</h3>
        <p>{props.item.summary}</p>
      </div>
    </div>
  );
}

export default DevOpsPractices;
