import { NavLink } from "react-router-dom";

function About() {
  return (
    <div className="row mb-3 mt-3">
      <div className="container mb-1 text-left">
        <h1 className="mb-4 mt-4 text-blue">Tomasz Siemienik - About me</h1>
        <p>
          I'm a contractor with 15+ years of commercial experience. I used to
          work as a permanent staff in on-site Dublin offices for five years.
          Currently, I moved back to Poland and continue my service working
          remotely as a consultant for Ireland/UK-based companies.
          <br />
          <br />
          My background is in development (frontend and APIs). After a couple of
          years, I became interested in the automated delivery process, CI/CD
          pipelines, public cloud providers and all modern toolsets of the
          DevOps delivery chain. I have 8 years of experience in Cloud Platform
          Engineering Teams. Also, I used to lead these teams and their delivery
          (my max was 7 devs of different experience levels).
          <br />
          <br />
          You can find me on{" "}
          <a
            href="https://www.linkedin.com/in/tomasz-siemienik-a0293859/"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>{" "}
          or navigate through this page to read more about me and the buissness
          I own.
          <br />
          <br />I described my presonal experience in the areas I may help you
          with in your organisation:
        </p>
        <div className="d-flex flex-column justify-content-start">
          <NavLink to="/devops" className="mb-2 text-blue">
            <i className="fa fa-angle-right mr-2"></i>DevOpsPractices
          </NavLink>
          <NavLink to="/aws" className="mb-2 text-blue">
            <i className="fa fa-angle-right mr-2"></i>AWS Cloud
          </NavLink>
          <NavLink to="/paas" className="mb-2 text-blue">
            <i className="fa fa-angle-right mr-2"></i>Platform as a Service
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default About;
