import { NavLink } from "react-router-dom";

function EKS() {
  return (
    <div className="row mb-3 mt-3">
      <div className="container mb-1 text-left">
        <h1 className="mb-4 mt-4 text-blue">
          Case study: AWS EKS + Docker + Helm + ArgoCD + Gitlab
        </h1>
        <p>
          <NavLink
            to="/casestudy/ec2"
            className="text-blue mb-5 mt-5 align-right"
          >
            <i className="fa fa-angle-right mr-2"></i>Next case study: AWS EC2 + Packer +
            Terraform + Ansible + Jenkins
          </NavLink>
        </p>
        <p>
          Below diagram illustrates the GitOps approach with ArgoCD as a
          Continous Delivery tool that constantly monitors Git repositories and
          the state of connected Kubernetes clusters. The Git repository
          describes the state of the clusters. Any manual change not defined in
          the git repo will affect rollbacking modifications to the state
          described in the repo. The auto-heal option of ArgoCD does this.
          <img
            className="container"
            src={require("../../assets/casestudy/argocd-eks.png")}
            alt="AWS EKS + Docker + Helm + ArgoCD + Gitlab"
          />
        </p>
        <p>
          <strong>Repositories split:</strong>
          <ul>
            <li>
              Applications - The docker image and Helm chart should be stored in
              the same repository as the application. CI pipeline should
              generate all the artefacts like images and helm charts and upload
              them to the registries.
            </li>
            <li>
              ArgoCD Apps of Apps - This repository should contain ArgoCD
              configuration required to deploy application sets or app of apps
              (ArgoCD pattern to deploy group of applications together as single
              ArgoCD application). This part should be separated from
              Environment configuration to increase reusability and sharing
              across multiple envs and teams.
            </li>
            <li>
              Environment configuration - as for now, ArgoCD doesn't offer
              reading environment config from a remote repository, it has to be
              in the same repo as app of apps is, but it may be stored in
              separate directory structure to easily move outside when ArgoCD
              will provide the required functionality.
            </li>
          </ul>
        </p>
        <p>
          <strong>ArgoCD placement options:</strong>
          <ul>
            <li>
              ArgoCD provisioned on the same cluster as its monitoring (1:1):
              this solution is not ideal because of the following:
              <ol>
                <li>
                  resource consumption as you install all ArgoCD pods on each
                  cluster
                </li>
                <li>
                  operator comfort as you have to work with multiple consoles
                </li>
                <li>
                  when your cluster has a problem, ArgoCD may have it as well,
                  so deployments/inspections maybe not be possible
                </li>
              </ol>
            </li>
            <li>
              ArgoCD is provisioned on a single cluster per AWS account, e.g.
              dev, nonprod, and prod. ArgoCD instance may be configured to
              maintain many clusters. Usually, it's approved by Security Teams
              to group development and production workloads and maintain them
              from very few instances of ArgoCD.
            </li>
            <li>
              Single ArgoCD to maintain all the company workloads. This maybe
              the best fit for small organisations to use a single interface to
              manage all the clusters and deployments. In this case, security
              may be compromised (dev/prod access).
            </li>
          </ul>
        </p>
        <p>
          <strong>Notice: </strong>You may have multiple repositories containing
          ArgoCD applications definition to ship different workflows into the
          Kubernetes clusters. Usually, the workload boundary is team ownership:
          different sets of applications. Another good use case is when you
          deploy Kubernetes addons using ArgoCD - owned by DevOps/Platform
          teams, then deploy your business applications.
        </p>
        <p>Check other case studies:</p>
        <div className="d-flex flex-column justify-content-start">
          <NavLink to="/casestudy/ec2" className="mb-2 text-blue">
            <i className="fa fa-angle-right mr-2"></i>Deploying application on
            AWS EC2 instance with Packer, Terraform, Ansible and Jenkins
          </NavLink>
          <NavLink to="/casestudy/ecs" className="mb-2 text-blue">
            <i className="fa fa-angle-right mr-2"></i>Deploying application on
            AWS ECS Fargate with Docker, Terraform and GitHub Actions
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default EKS;
