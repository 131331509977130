import { NavLink } from "react-router-dom";

function AreaItem(props) {
  return (
    <div className="col-md-4 mb-1">
      <img src={props.course.image} alt="" />
      <h3 className="mb-4 mt-4 text-blue">{props.course.name}</h3>
      <p>
        {props.course.summary}
        <NavLink to={props.course.read_more} className="text-blue ml-2">
          Read more
        </NavLink>
      </p>
    </div>
  );
}

export default AreaItem;
