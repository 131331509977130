import { NavLink } from "react-router-dom";

function Header() {
  return (
    <div className="header">
      <div className="container-fluid bg-dark">
        <div className="container">
          <div className="row py-2 px-lg-5">
            <div className="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
              <div className="d-inline-flex align-items-center text-white">
                <small>
                  <i className="fa fa-envelope mr-2"></i>
                  tomasz.siemienik@core21.pl
                </small>
              </div>
            </div>
            <div className="col-lg-6 text-center text-lg-right">
              <div className="d-inline-flex align-items-center">
                <a
                  className="text-white px-2"
                  href="https://ie.linkedin.com/in/tomasz-siemienik-a0293859"
                  title="LinkedIn Profile"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-blue h-3"></div>
      <div className="container-fluid p-0 bg-grey">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light py-3 py-lg-0 px-lg-5">
            <a href="/" className="navbar-brand ml-lg-3">
              <img
                className="infinity-loop"
                src={require("../assets/core21-logo.png")}
                alt="CORE21 logo"
              />
            </a>
            <button
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-between px-lg-3"
              id="navbarCollapse"
            >
              <div className="navbar-nav mx-auto py-0">
                <NavLink to="/devops" className="nav-item nav-link">
                  DevOps Practicies
                </NavLink>
                <NavLink to="/aws" className="nav-item nav-link">
                  AWS Cloud
                </NavLink>
                <NavLink to="/paas" className="nav-item nav-link">
                  PAAS
                </NavLink>
                <NavLink to="/leadership" className="nav-item nav-link">
                  Leadership
                </NavLink>
                <NavLink to="/casestudy/eks" className="nav-item nav-link">
                  Case Studies
                </NavLink>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="container-fluid bg-blue h-3"></div>
    </div>
  );
}

export default Header;
