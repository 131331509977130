import { NavLink } from "react-router-dom";

function EC() {
  return (
    <div className="row mb-3 mt-3">
      <div className="container mb-1 text-left">
        <h1 className="mb-4 mt-4 text-blue">
          Case study: AWS EC2 + Packer + Terraform + Ansible + Jenkins
        </h1>
        <p>
          <NavLink
            to="/casestudy/ecs"
            className="text-blue mb-5 mt-5 align-right"
          >
            <i className="fa fa-angle-right mr-2"></i>Next case study: AWS ECS
            Fargate + Docker + Terraform + GitHub Actions
          </NavLink>
        </p>
        <p>
          A purpose of this high-level diagram is to show what kinds of the jobs
          can be executed on Jenkins and what tools to use when working with AWS
          EC2 instances.
          <img
            className="container"
            src={require("../../assets/casestudy/ec2.png")}
            alt="AWS EC2 + Packer + Terraform + Ansible + Jenkins"
          />
        </p>
        <p>
          <strong>AWS Cloud components</strong> This diagram contains only
          EC2-related resources:
          <ul>
            <li>
              EC2 instances are processing units that allow deploying
              applications on it. Depending on the need, different types (CPU,
              memory or general-use instances can be provisioned). Other types
              of disks may be added as storage, for instance.
            </li>
            <li>
              AutoScaling group is responsible for placing strategy and
              automatic scaling up/down of the services (in the defined limit)
              depending on the current need in different availability zones for
              high availability.
            </li>
            <li>
              AMIs are machine images and snapshots that contain all the
              programs pre-installed and configured on the EC2 instance. They
              are used for quickly autoscaling your services and ensuring that
              all the provisioned instances are configured similarly. Large
              organisations may have a central, secured AMI storage space (S3)
              and central team, giving read-only access to other teams.
            </li>
            <li>
              A load balancer is used to direct user traffic to the EC2
              instances. For security reasons, you may keep your EC2 instances
              in the private vpc/subnet and then expose them through a public
              load balancer.
            </li>
          </ul>
        </p>
        <p>
          <strong>Terraform / Terragrunt </strong>
          Using terraform modules and terragrunt is a great way to define your
          Infrastructure as a Code (IaC). Terraform increases reusability, while
          terragrunt provides a way to encapsulate configuration data from the
          module logic. All the AWS components can be provisioned this way.
          Jenkinsjob can contain terragrunt/terraform commands to provision
          infrastructure.
        </p>
        <p>
          <strong>Jenkins </strong>
          is used as a CI/CD pipeline layer. It's possible to deliver a pipeline
          building AWS AMI, provisioning Infrastructure and Deploying services;
          however, this isn't recommended as different parts of your system
          change with different frequencies and execution times. However, a
          single GIT repository may contain multiple Jenkinsfiles (job
          definitions). That's why it's worth splitting responsibilities as on
          the diagram.
          <br />
          <br /> You may also want to build your own DSL library to predefine
          once steps or even whole pipelines and reuse in many Jenkinsfiles.
        </p>
        <p>
          <strong>Packer </strong>, it's a tool that is used to generate machine
          images from code. It works with a JSON file that contains a list of
          the provisioners and commands to execute on the base AMI. It can be
          used to install and configure required tools. To increase AMI
          reusability , applications shouldn't be installed as a part of the AMI
          but rather deployed later.
        </p>
        <p>
          <strong>Ansible </strong> is a configuration tool that can be used to
          ssh to the provisioned EC2 instance and execute commands directly on
          it. Usually, it's related to copying application artefacts, providing
          parameters and starting a service.
        </p>
        <p>
          <br /> Check other case studies:
        </p>
        <div className="d-flex flex-column justify-content-start">
          <NavLink to="/casestudy/ecs" className="mb-2 text-blue">
            <i className="fa fa-angle-right mr-2"></i>Deploying application on
            AWS ECS Fargate with Docker, Terraform and GitHub Actions
          </NavLink>
          <NavLink to="/casestudy/eks" className="mb-2 text-blue">
            <i className="fa fa-angle-right mr-2"></i>Deploying application on
            AWS EKS with Docker, Helm, ArgoCD and Gitlab CI
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default EC;
