import "./AreaItem.js";
import AreaItem from "./AreaItem.js";

function CoursesList(props) {
  return (
    <div className="container-fluid position-relative text-black py-1">
      <div className="container mt-1 pt-5">
        <div className="row">
          {props.courses.map((course, index) => {
            return <AreaItem key={course.id} course={course} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default CoursesList;
