function Leadership() {
  const items = [
    {
      id: "1",
      name: "Agile planning and execution process",
      image: require("../assets/project-management.png"),
      summary:
        "Daily Demos, Retrospectives and Planning sessions are the most common activities of the Scrum sprint iteration, which I lead. Occasionally the team may need session knowledge sharing, accident retrospective or roadmap planning. I use Atlassian Products or Azure DevOps to organise the process depending on the customer. I was also using tools like miro.com, metroretro.io or scrumpoker-online.org..",
    },
    {
      id: "2",
      name: "Team management",
      image: require("../assets/meeting.png"),
      summary:
        "I participated in the new hire (technical interview) and onboarding process. Also, I lead 1:1s and manage team focus and motivation to achieve goals.",
    },
    {
      id: "3",
      name: "Engaging stakeholders",
      image: require("../assets/networking.png"),
      summary:
        "Being a part of the Core/Platform team requires a lot of integration with other teams as we create products and processes they will use. A regular part of this work is educating stakeholders through workshops, slide decks and demo sessions. Also, it's important to engage other central teams to agree on the patterns and eliminate blockers or get access to some of the integration tools.",
    },
  ];

  return (
    <>
      <div className="row mb-3 mt-3">
        <div className="container mb-1 text-left">
          <h1 className="mb-4 mt-4 text-blue">Leadership</h1>
          <p>
            In my career, I led mainly DevOps-oriented teams with team members
            of different advanced levels. I was a buddy and cared for the most
            junior staff to elevate them to higher levels and support them in
            their carrier growth. I also don't afraid to delegate some of the
            tasks to the most senior people in the team to give them
            possibilities to challenge themselves and constantly keep them
            motivated. I can lead Agile ceremonies and take over the
            conversation with the stakeholders. I share my knowledge on the
            internal and external demo sessions.
          </p>
        </div>
      </div>
      <ItemsList items={items} />
    </>
  );
}

function ItemsList(props) {
  return (
    <div className="container-fluid bg-grey-2">
      <div className="container mb-4 mt-5 text-center">
        {props.items.map((item, index) => {
          return <Item key={item.id} item={item} />;
        })}
      </div>
    </div>
  );
}

function Item(props) {
  return (
    <div className="row mb-3 mt-3">
      <div className="container col-md-2 pb-3 pt-3 rounded-5 bg-grey">
        <img
          className="container max-width-150"
          src={props.item.image}
          alt=""
        />
      </div>
      <div className="container col-md-10 mb-1 text-left">
        <h3 className="mb-1 mt-1 text-blue">{props.item.name}</h3>
        <p>{props.item.summary}</p>
      </div>
    </div>
  );
}

export default Leadership;
