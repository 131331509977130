function PrivacyPolicy() {
  return (
    <div className="container-fluid bg-grey-2">
      <div className="container mb-4 mt-5 text-center">
        <div className="row mb-3 mt-3">
          <div className="container col-md-12 mb-1 text-left">
            <h1 className="mb-4 mt-4 text-blue">Privacy Policy</h1>
            <p>This page doesn't collect/store any user data.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
