import { NavLink } from "react-router-dom";

function AWSCloud() {
  const items = [
    {
      id: "1",
      name: "Simple Page",
      image: require("../assets/internet.png"),
      summary:
        "Deploying a simple application like this React.js page is probably overkill to ship it to AWS, but it makes sense if it's one of many applications the company owns. For serving this requirement following AWS services were used: Route53 (DNS resolution), Certificate Manager (delivering public certificate), CloudFront (CDN), Application Load Balancer (port forwarding), Security Groups (enable traffic on some ports), Elastic Container Service (computing), AWS S3 (static content storage), AWS RDS (database), Elastic Registry Service (artefact storage), AWS Organisations & IAM (to secure access to the console).",
      more: "/casestudy/ecs",
    },
    {
      id: "2",
      name: "EC2 Instances",
      image: require("../assets/servers.png"),
      summary:
        "If you have some custom services that, for different reasons, can't be dockerised (massive mono-service legacy systems or stateful applications), that's the way to go. I used tools such as Packer to build AMIs (Amazon Machine Images) and Ansible to pre-configure instances with user data scripts.",
      more: "/casestudy/ec2",
    },
    {
      id: "3",
      name: "Kubernetes / AWS EKS",
      image: require("../assets/cargo.png"),
      summary:
        "AWS provides managed Kubernetes service (EKS). I used it widely, taking care of upgrades and shipping/configuring required add-ons. This was done automatically with tools like terraform (infrastructure) and helm or ArgoCD (addons part). Depending on the need, many different add-ons may be installed to enable monitoring and logging (e.g. CloudWatch/DataDog/NewRelic/Prometheusagent or FluentD/Splunk log collectors) capabilities or increase security/networking needs (e.g. Istio).",
      more: "/casestudy/eks",
    },
    {
      id: "4",
      name: "Serverless",
      image: require("../assets/lambda.png"),
      summary:
        "With this pattern, you can create fully scalable, resilient applications by paying only for the resources you are using at the time you do. The most common components of this pattern are AWS CloudFront, API Gateway, AWS Lambda, DynamoDB, and AWS SQS/SNS queues.",
    },
  ];

  return (
    <>
      <div className="row mb-3 mt-3">
        <div className="container mb-1 text-left">
          <h1 className="mb-4 mt-4 text-blue">Amazon Web Services Cloud</h1>
          <p>
            I'm certificated AWS Cloud Practitioner and{" "}
            <a href="https://aw.certmetrics.com/amazon/public/verification.aspx">
              AWS Certified Solutions Architect - Associate
            </a>{" "}
            <strong>Credential ID 37TKK3CDM144QDGD</strong>. In my daily I work
            with AWS Services. Depends on the need I can configure them manually
            or deliver Infrastructure as a Code (IaC) with tools like Ansible &
            Terraform.
            <br />
            <br />
            Below I would like to share some of common patterns that I used to
            work with:
          </p>
        </div>
      </div>
      <ItemsList items={items} />
    </>
  );
}

function ItemsList(props) {
  return (
    <div className="container-fluid bg-grey-2">
      <div className="container mb-4 mt-5 text-center">
        {props.items.map((item, index) => {
          return <Item key={item.id} item={item} />;
        })}
      </div>
    </div>
  );
}

function Item(props) {
  return (
    <div className="row mb-3 mt-3">
      <div className="container col-md-2 pb-3 pt-3 rounded-5 bg-grey">
        <img
          className="container max-width-150"
          src={props.item.image}
          alt=""
        />
      </div>
      <div className="container col-md-10 mb-1 text-left">
        <h3 className="mb-1 mt-1 text-blue">{props.item.name}</h3>
        <p>
          {props.item.summary} <MoreLink item={props.item} />
        </p>
      </div>
    </div>
  );
}

function MoreLink(props) {
  if (props.item.more) {
    return (
      <NavLink to={props.item.more} className="mb-2 text-blue">
        read more
      </NavLink>
    );
  }
  return null;
}

export default AWSCloud;
