import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="container-fluid bg-blue h-3"></div>
      <div
        className="container-fluid position-relative bg-dark text-white-50 py-1"
        style={{ margin: "0px" }}
      >
        <div className="container mt-1 pt-5">
          <div className="row">
            <div className="col-md-4 mb-1">
              <h3 className="text-white mb-4">Get In Touch</h3>
              <p>
                <i className="fa fa-map-marker-alt mr-2"></i>
                Bielsko-Biała, Poland
              </p>
              <p>
                <i className="fa fa-envelope mr-2"></i>
                tomasz.siemienik@core21.pl
              </p>
              <p>
                <a
                  className="text-white-50 mr-2"
                  href="https://www.linkedin.com/in/tomasz-siemienik-a0293859/"
                >
                  <i className="fab fa-linkedin-in"></i> Tomasz Siemienik
                  Profile
                </a>
              </p>
            </div>
            <div className="col-md-3 mb-1">
              <h3 className="text-white mb-4">Resources</h3>
              <div className="d-flex flex-column justify-content-start">
                <NavLink to="/about" className="text-white-50 mb-2">
                  <i className="fa fa-angle-right mr-2"></i>About
                </NavLink>
                <NavLink to="/casestudy/eks" className="text-white-50 mb-2">
                  <i className="fa fa-angle-right mr-2"></i>Case studies
                </NavLink>
                <NavLink to="/blog" className="text-white-50 mb-2">
                  <i className="fa fa-angle-right mr-2"></i>Blog
                </NavLink>
                <NavLink to="/privacy-policy" className="text-white-50 mb-2">
                  <i className="fa fa-angle-right mr-2"></i>Privacy Policy
                </NavLink>
              </div>
            </div>
            <div className="col-md-5 mb-1">
              <a href="/" className="navbar-brand">
                <h3 className="mt-n2 text-uppercase text-white">
                  CORE21 sp. z o.o.
                </h3>
              </a>
              <p className="m-1">
                My name is Tomasz Siemienik and I'm CORE21 sp. z o.o. CEO. I
                used to work on-site for over 5 years in Dublin based IT
                companies. I have also pre-COVID remote work experience.
              </p>
              <p className="m-1">
                I'm solid partner in AWS Cloud / DevOps / Platform Engineering
                area. Please reach me out, so we can speak how I can help you.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-blue h-3"></div>
      <div className="container-fluid bg-dark py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-md-left">
              <p className="m-0 text-white">
                Copyright &copy;{" "}
                <a className="text-white" href="/">
                  core21.pl
                </a>
                . All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
