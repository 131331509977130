function Paas() {
  return (
    <div className="container-fluid bg-grey-2">
      <div className="container mb-4 mt-5 text-center">
        <div className="row mb-3 mt-3">
          <div className="container col-md-8 mb-1 text-left">
            <h1 className="mb-4 mt-4 text-blue">Platform Engineering</h1>
            <p>
              Platform Engineering is an effort that large organisations make to
              standardise some of the standard processes within the
              organisation.{" "}
              <strong>
                Probably for everyone, "Platform" may mean something different,
                and this is still good as Platform is treated as an alignment of
                the surface between other teams
              </strong>
              ; it may be something small and simple like documentation or a set
              of standard practices, e.g. onboarding or an organised way of
              planning and evaluating projects. For others, it maybe something
              more wider, like building a common set of APIs and defining
              standards, these APIs should all follow. For the most advanced
              forms, Platform may mean delivering infrastructure and delivery
              pipelines that are standard across involved departments.
            </p>
            <p>
              I was working in a few Core/Platform teams for different
              organisations. There were all on a different level when we started
              and targeted other goals (advanced level). I know patterns in this
              area and saw things that worked and those that didn't. I can help
              your company to elevate in this area.
            </p>
            <p>
              <strong>
                One of the biggest risks is not giving enough power to these
                teams
              </strong>
              . Platform teams work across silos, touching different areas of
              the business. Part of our job is to educate other departments and
              involve stakeholders to break the silos and improve flaky
              processes on the organisation level.
            </p>
          </div>
          <div className="container col-md-4 pb-3 pt-3 rounded-5 bg-grey">
            <img
              className="container max-width-350"
              src={require("../assets/offshore.png")}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Paas;
