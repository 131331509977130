import { NavLink } from "react-router-dom";

function ECS() {
  return (
    <div className="row mb-3 mt-3">
      <div className="container mb-1 text-left">
        <h1 className="mb-4 mt-4 text-blue">
          Case study: AWS ECS Fargate + Docker + Terraform + GitHub Actions
        </h1>
        <p>
          <NavLink
            to="/casestudy/eks"
            className="text-blue mb-5 mt-5 align-right"
          >
            <i className="fa fa-angle-right mr-2"></i>Next case study: AWS EKS + Docker + Helm +
            ArgoCD + Gitlab
          </NavLink>
        </p>
        <p>
          ECS Fargate (serverless - no need to take care of EC2 nodes) as it's
          fully AWS-managed service for containers. It suits best for simple
          workloads (smaller organisations), POCs and early-stage migrations to
          docker because it doesn't require the installation of any add-ons.
          It's often the first step before moving to AWS EKS.
          <br />
          <br />
          The diagram below illustrates how you can integrate ECS workloads with
          other AWS services and make it a fully working production solution to
          serve your containerised applications.
          <img
            className="container"
            src={require("../../assets/casestudy/ecs-fargate.png")}
            alt="AWS ECS Fargate + Docker + Terraform + GitHub Actions"
          />
        </p>
        <p>
          <strong>Infrastructure as a Code</strong> with Terraform modules and
          Terragrunt abstraction layer for configuring terraform modules,
          parameters are used to describe all the needed AWS cloud
          infrastructure and being able to provision and maintain it from the
          GitHub pipeline actions.
        </p>
        <p>
          <strong>Continous Integration / Continous Delivery</strong> using
          CI/CD tools like GitHub Actions allows you to describe what steps
          should be executed automatically while there are changes to the Git
          Repository. A complete pipeline contains many steps, as presented
          above. It's worth mentioning that the pipeline can fully execute
          application tests, build all needed artefacts and deploy an
          application to the AWS cloud.
        </p>
        <p>Check other case studies:</p>
        <div className="d-flex flex-column justify-content-start">
          <NavLink to="/casestudy/ec2" className="mb-2 text-blue">
            <i className="fa fa-angle-right mr-2"></i>Deploying application on
            AWS EC2 instance with Packer, Terraform, Ansible and Jenkins
          </NavLink>
          <NavLink to="/casestudy/eks" className="mb-2 text-blue">
            <i className="fa fa-angle-right mr-2"></i>Deploying application on
            AWS EKS with Docker, Helm, ArgoCD and Gitlab CI
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default ECS;
