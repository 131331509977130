function WorkInProgress() {
  return (
    <div className="container-fluid bg-grey-2">
      <div className="container mb-4 mt-5 text-center rounded-5 bg-grey">
        <div className="container-fluid position-relative text-black py-1">
          <div className="container mt-1 pt-5">
            <div className="row">
              <div className="col-md-12 mb-1">
                <img src={require("../assets/wip.png")} alt="" />
                <h3 className="mb-4 mt-4">Work in progress</h3>
                <p>We work on this content, but it's not available yet!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkInProgress;
