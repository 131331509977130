import CoursesList from "../components/AreasList";
import { useParams } from "react-router-dom";

function MainPage() {
  const courses = [
    {
      id: "1",
      name: "DevOps Practicies",
      image: require("../assets/devops.png"),
      read_more: "/devops",
      summary:
        "Analyse bottlenecks in the delivery process, collect DORA metrics, architect and propose continuous & iterative improvements in the CI/CD process to increase frequency and speed of shipping to production and decrease the failure rate of your system.",
    },
    {
      id: "2",
      name: "AWS Cloud",
      image: require("../assets/cloud.png"),
      read_more: "/aws",
      summary:
        "Use well-architected AWS Cloud infrastructure to ship your services securely, reliably, and scalable way. Use Infrastructure as a Code (IaC) and GitOps approach.",
    },
    {
      id: "3",
      name: "Platform Engineering",
      image: require("../assets/flow.png"),
      read_more: "/paas",
      summary:
        "Define patterns, and build parametrised and standardised components of your infrastructure and delivery pipeline to increase delivery speed and reduce cognitive load for your development teams.",
    },
  ];

  const { name = "" } = useParams();

  return (
    <div className="container mt-5">
      <p>
        <strong>
          Our experience in building scallable DevOps capabilities in the
          organisation:
        </strong>
      </p>
      <div className="container-fluid bg-grey-2">
        <div className="container mb-4 mt-5 text-center rounded-5 bg-grey">
          <RenderCourses
            courses={courses}
            requestedCourseName={name.toLowerCase()}
          />
        </div>
      </div>
    </div>
  );
}

function RenderCourses(props) {
  const courses = props.courses.filter((course) =>
    course.name.toLowerCase().includes(props.requestedCourseName)
  );

  return <CoursesList courses={courses} />;
}

export default MainPage;
